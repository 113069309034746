require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/b20f391e80b40bad68a270a31cbcf9d4/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4'] = async function fn_b20f391e80b40bad68a270a31cbcf9d4(key) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return (getObjectProperty((await ( async function (language) { return BackendlessUI.I18n.getDictionary(language) })(((function () { return BackendlessUI.I18n.getLanguage() })()))), (String('lang_') + String(key))))
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/landing/components/234512b19f0abf5a90082f583cf7d00d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://dynamicrelations.no', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error, objectId, existingSurvey;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['numPerPage'] = 7;
  ___arguments.context.pageData['counter'] = 1;
  ___arguments.context.pageData['nextCount'] = (getObjectProperty(___arguments.context.pageData, 'numPerPage'));
  try {
    if (!((getObjectProperty(___arguments.context.pageData, 'code')) && (getObjectProperty(___arguments.context.pageData, 'type')))) {
      throw (new Error('Missing page parameters'))
    }
    existingSurvey = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})(([(getObjectProperty(___arguments.context.pageData, 'code')),'-',(getObjectProperty(___arguments.context.pageData, 'type'))].join(''))));
    if ((typeof (getObjectProperty(___arguments.context.pageData, 'id')) === 'string') && (getObjectProperty(___arguments.context.pageData, 'id')).length == 36) {
      objectId = (getObjectProperty(___arguments.context.pageData, 'id'));
    } else {
      if (existingSurvey !== null && !Array.isArray(existingSurvey) && (typeof existingSurvey === 'object')) {
        objectId = (getObjectProperty(existingSurvey, 'objectId'));
      } else {
        objectId = '';
      }
    }
    ___arguments.context.pageData['respondent'] = (await Backendless.Request.get(`${Backendless.appPath}/services/RelCo/getRespondent`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'code': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'code'))),'type': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'type'))),'id': JSON.stringify(objectId) }).send());
    if (!((getObjectProperty(___arguments.context.pageData, 'respondent.completed')) === null)) {
      Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug('Respondent has already completed');
      localStorage.removeItem(([(getObjectProperty(___arguments.context.pageData, 'code')),'-',(getObjectProperty(___arguments.context.pageData, 'type'))].join('')));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d6b010230bebd53da4c8be872b6ae96', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f239ba1b4d9eabee87038b2fdf9cac3d', true);
    } else {
      localStorage.setItem(([(getObjectProperty(___arguments.context.pageData, 'code')),'-',(getObjectProperty(___arguments.context.pageData, 'type'))].join('')), JSON.stringify((getObjectProperty(___arguments.context.pageData, 'respondent'))));
      Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug('Respondent data loaded');
    }
    ___arguments.context.pageData['questions'] = (await Backendless.Request.get(`${Backendless.appPath}/services/RelCo/getQuestions`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'type': JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'respondent')), 'type'))),'id': JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'respondent')), 'objectId'))) }).send());

  } catch (error) {
    if ((getObjectProperty(error, 'status')) == '406') {
      await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('11926c332175e89c03f27a095cec842f', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('max_error')), 'error');
    } else {
      await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('11926c332175e89c03f27a095cec842f', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('load_error')), 'error');
      Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug(error);
    }
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d6b010230bebd53da4c8be872b6ae96', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('855d32f761697156829c3d110386a102', true);

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/landing/components/41ec1ebbbbcbd1c532b3b48ff19f4935/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d6b010230bebd53da4c8be872b6ae96', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('320f5b4857d45ab5d9aada8ffda9310d', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('28ab62c7d41b84e5fa3f078f00676f17', true);

  },
  /* handler:onClick */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('button_start'))

  },
  /* handler:onTextAssignment */
  /* content */
}))

define('./pages/landing/components/718722d1de4d652edb05f454ccaa9802/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var tempCounter, totalCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  totalCount = 70;
  tempCounter = totalCount - (getObjectProperty(___arguments.context.pageData, 'questions')).length;

  return ([(await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('question_title_part1')),' ',tempCounter + (getObjectProperty(___arguments.context.pageData, 'counter')),'-',tempCounter + (getObjectProperty(___arguments.context.pageData, 'nextCount')),' ',(await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('question_title_part2')),' ',totalCount].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/f3a6d557d98925b1dcdfa302f6d63637/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
      return ((Object.keys(___arguments.context.getComponentDataStoreByUid('af12a875a1d82ec3ed13099450c6492b'))).length != ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('6f89b55e4914de1a533a8585c49a223d')).length ? true : false)

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var nextCount, numQuestions, returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  nextCount = (getObjectProperty(___arguments.context.pageData, 'nextCount'));
  numQuestions = (getObjectProperty(___arguments.context.pageData, 'questions')).length;
  if (nextCount < numQuestions) {
    returnValue2 = (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('button_continue'));
  } else {
    returnValue2 = (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('button_finish'));
  }

  return returnValue2

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/landing/components/af12a875a1d82ec3ed13099450c6492b/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var nextCount, newCount, error, upsertAnswerList, upsertResults, upsertAnswerRecord, questionObjectId, currentAnswer, answers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  answers = (Object.keys(___arguments.context.dataModel));
  upsertAnswerList = [];
  try {
    for (var questionObjectId_index in answers) {
      questionObjectId = answers[questionObjectId_index];
      currentAnswer = (getObjectProperty(___arguments.context.dataModel, questionObjectId));
      upsertAnswerRecord = ({ [`Answer`]: currentAnswer,[`RelCo_Respondent`]: ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'respondent.objectId')) }),[`RelCo_Question`]: ({ [`objectId`]: questionObjectId }) });
      addItemToList(upsertAnswerList, upsertAnswerRecord);
    }
    upsertResults = (await Backendless.Request.post(`${Backendless.appPath}/services/RelCo/postAnswers`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(upsertAnswerList)));
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('11926c332175e89c03f27a095cec842f', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('save_success')), 'success');
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1a64ec4fe3a152387a618d7dfd446565')));

  } catch (error) {
    if ((getObjectProperty(error, 'status')) == '406') {
      await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('11926c332175e89c03f27a095cec842f', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('max_error')), 'error');
    } else {
      await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('11926c332175e89c03f27a095cec842f', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('save_error')), 'error');
      Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug(error);
    }

  }
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('af12a875a1d82ec3ed13099450c6492b');
  newCount = (getObjectProperty(___arguments.context.pageData, 'counter')) + (getObjectProperty(___arguments.context.pageData, 'numPerPage'));
  nextCount = newCount + ((getObjectProperty(___arguments.context.pageData, 'numPerPage')) - 1);
  if (newCount < (getObjectProperty(___arguments.context.pageData, 'questions')).length) {
    ___arguments.context.pageData['counter'] = newCount;
    ___arguments.context.pageData['nextCount'] = nextCount;
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1a64ec4fe3a152387a618d7dfd446565', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f239ba1b4d9eabee87038b2fdf9cac3d', true);
  }

  },
  /* handler:onSubmit */
  /* handler:onReset */
  ['onReset'](___arguments) {
    var question;


  var question_list = (Object.keys(___arguments.prevData));
  for (var question_index in question_list) {
    question = question_list[question_index];
    delete ___arguments.context.dataModel[question];
  }

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/landing/components/6f1c384e14254ce77353682506f3f335/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedValueAssignment */
  ['onCheckedValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af12a875a1d82ec3ed13099450c6492b'), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1bcb0801c71add4a8ea251346f03494c'), 'objectId'))))

  },
  /* handler:onCheckedValueAssignment */
  /* content */
}))

define('./pages/landing/components/aaeaeccada15a2ea00daf5991ad47a45/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedValueAssignment */
  ['onCheckedValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af12a875a1d82ec3ed13099450c6492b'), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1bcb0801c71add4a8ea251346f03494c'), 'objectId'))))

  },
  /* handler:onCheckedValueAssignment */
  /* content */
}))

define('./pages/landing/components/b0dadc031c9e3fa5972bd6468a264bb3/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedValueAssignment */
  ['onCheckedValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af12a875a1d82ec3ed13099450c6492b'), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1bcb0801c71add4a8ea251346f03494c'), 'objectId'))))

  },
  /* handler:onCheckedValueAssignment */
  /* content */
}))

define('./pages/landing/components/4734ce2f44e73e2d48d8fa9d14ef7846/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedValueAssignment */
  ['onCheckedValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af12a875a1d82ec3ed13099450c6492b'), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1bcb0801c71add4a8ea251346f03494c'), 'objectId'))))

  },
  /* handler:onCheckedValueAssignment */
  /* content */
}))

define('./pages/landing/components/66a9b7835a63554510763c91092ed45a/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedValueAssignment */
  ['onCheckedValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af12a875a1d82ec3ed13099450c6492b'), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1bcb0801c71add4a8ea251346f03494c'), 'objectId'))))

  },
  /* handler:onCheckedValueAssignment */
  /* content */
}))

define('./pages/landing/components/7f753699148910ca4bbd17c1a0459ad1/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedValueAssignment */
  ['onCheckedValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af12a875a1d82ec3ed13099450c6492b'), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1bcb0801c71add4a8ea251346f03494c'), 'objectId'))))

  },
  /* handler:onCheckedValueAssignment */
  /* content */
}))

define('./pages/landing/components/82a746dbaacca89148a7c36d50997b54/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedValueAssignment */
  ['onCheckedValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af12a875a1d82ec3ed13099450c6492b'), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1bcb0801c71add4a8ea251346f03494c'), 'objectId'))))

  },
  /* handler:onCheckedValueAssignment */
  /* content */
}))

define('./pages/landing/components/23beb665a7c378835dccd222dd9e8e22/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('survey_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/16f7e1cb79744c39343a4ffe81fc9bad/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('survey_completed_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/f39af88a62a33bfc904fd88ac41b0c38/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('survey_completed_text'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/12c5d83196812cadc06e4d47d19badc8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  returnValue2 = null;
  if ((getObjectProperty(___arguments.context.pageData, 'respondent')) !== null && !Array.isArray((getObjectProperty(___arguments.context.pageData, 'respondent'))) && (typeof (getObjectProperty(___arguments.context.pageData, 'respondent')) === 'object')) {
    returnValue2 = (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']((String('survey_text_') + String(getObjectProperty(___arguments.context.pageData, 'respondent.type')))));
    returnValue2 = (returnValue2.replace((/\[name\]/gi), (getObjectProperty(___arguments.context.pageData, 'respondent.Rel_Assessment.name'))));
  }

  return returnValue2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/c5f499874d2399a561b3f524af21b6cd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('survey_text'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/28ab62c7d41b84e5fa3f078f00676f17/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('28ab62c7d41b84e5fa3f078f00676f17', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f239ba1b4d9eabee87038b2fdf9cac3d', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('320f5b4857d45ab5d9aada8ffda9310d', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1a64ec4fe3a152387a618d7dfd446565', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d6b010230bebd53da4c8be872b6ae96', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/fd03a382841ab6b966a9dd84435dd379/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c8b9e3fc4ac671c4af256553a3177931'), 'Question')).replace((new RegExp('\\[navn\\]', 'gi')), (getObjectProperty(___arguments.context.pageData, 'respondent.Rel_Assessment.firstname'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/684cf5066f2c779dbea59069a9bdf29c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.linkedin.com/company/dynamicrelations/', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/ac4282b18fc5fbca16bcf23245829c56/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.facebook.com/dynamicrelations.no', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/dc910504ce733871047810a5619ceabc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('survey_statistics_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/3185d151f38564ed3a9ce6c9655f92a7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('survey_statistics_text'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/a05a616c18572716e6af947db755e4c7/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('button_questionaire'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7cba03e47c35d51b5d225c0c86b4f146'), 'gender')) === 'string') || !(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7cba03e47c35d51b5d225c0c86b4f146'), 'age')) === 'number' && !isNaN((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7cba03e47c35d51b5d225c0c86b4f146'), 'age')))) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7cba03e47c35d51b5d225c0c86b4f146'), 'age')) < '15' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7cba03e47c35d51b5d225c0c86b4f146'), 'age')) > '90')

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing/components/10443a7f59ac13945a316a0d1e5e99df/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('label_age'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/landing/components/44eee77cecb104966f98451125b6ca42/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('label_male'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/landing/components/5dce128c128d2c4d239bc2ed790ae685/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('label_female'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/landing/components/7cba03e47c35d51b5d225c0c86b4f146/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, respondent;


  try {
    respondent = (await Backendless.Request.put(`${Backendless.appPath}/services/RelCo/saveStatistics`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(___arguments.context.dataModel)));
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('11926c332175e89c03f27a095cec842f', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('save_success')), 'success');

  } catch (error) {
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error(error);
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('11926c332175e89c03f27a095cec842f', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('save_error')), 'error');

  } finally {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('320f5b4857d45ab5d9aada8ffda9310d', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1a64ec4fe3a152387a618d7dfd446565', true);
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1a64ec4fe3a152387a618d7dfd446565')));

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/landing/components/734b9e88b4cec6c6d1c7bb27291cac31/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedValueAssignment */
  ['onCheckedValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af12a875a1d82ec3ed13099450c6492b'), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1bcb0801c71add4a8ea251346f03494c'), 'objectId'))))

  },
  /* handler:onCheckedValueAssignment */
  /* content */
}))

define('./pages/landing/components/6f89b55e4914de1a533a8585c49a223d/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'questions')).slice(((getObjectProperty(___arguments.context.pageData, 'counter')) - 1), (getObjectProperty(___arguments.context.pageData, 'counter')) + ((getObjectProperty(___arguments.context.pageData, 'numPerPage')) - 1)))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/landing/components/2bb9907f88cee764e8e7906ca75dca03/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('af12a875a1d82ec3ed13099450c6492b')[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1bcb0801c71add4a8ea251346f03494c'), 'objectId'))] = ___arguments.checkedValue;

  },
  /* handler:onChange */
  /* handler:onCheckedValueAssignment */
  ['onCheckedValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('af12a875a1d82ec3ed13099450c6492b'), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1bcb0801c71add4a8ea251346f03494c'), 'objectId'))))

  },
  /* handler:onCheckedValueAssignment */
  /* content */
}))

define('./pages/landing/components/a79d8fe22e2860f4149760c8c8d45255/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('survey_error_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/72e614d6bb6d30964f2f4e58c1184737/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('survey_error_text'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/report/components/234512b19f0abf5a90082f583cf7d00d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://dynamicrelations.no', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/report/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['disableReportButton'] = false;
  if (!(getObjectProperty(___arguments.context.pageData, 'code'))) {
    ___arguments.context.pageData['errorMessage'] = (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('load_error'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a633775dabc332e56bdd593819a3079', false);
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/report/components/cbe71264010c72163875cf5f13e4d508/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('report_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/report/components/a871a6ab4cde51863b522a2ba7071438/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('report_top10_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/report/components/f74692c2af8dabce3a8d8839014d0c6e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('header_question'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/report/components/7bc90fa51e22a8ee4b3b3d43ca942ef1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('report_bottom10_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/report/components/572e8f0b11177235fe7a87a2bd010c79/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('header_question'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/report/components/ba71c428ddf9b719e1f5648ff3d3b6f8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('header_topic'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/report/components/2cf798769d2511817b1c4e7d9b48bebe/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('header_score'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/report/components/c48c7a7f8f8fac95f6bf88b9d45ab0f5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('header_topic'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/report/components/cf93c413e3cce62c0decbc53798c0c26/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('header_score'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/report/components/cff8155d8f1f1661e34e6dccbedcb456/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((new RegExp('^[\\w-\\+\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$', '')).test((getObjectProperty(___arguments.context.pageData, 'email')))) || (getObjectProperty(___arguments.context.pageData, 'disableReportButton')) == true)

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, resultSet;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['disableReportButton'] = true;
  console.log(getObjectProperty(___arguments.context.pageData, 'disableReportButton'));
  try {
    if (!(getObjectProperty(___arguments.context.pageData, 'code'))) {
      throw (new Error('load_error'))
    }
    if (!(getObjectProperty(___arguments.context.pageData, 'email'))) {
      throw (new Error('email_error'))
    }
    resultSet = null;
    if (!(resultSet !== null && !Array.isArray(resultSet) && (typeof resultSet === 'object'))) {
      resultSet = (await Backendless.Request.get(`${Backendless.appPath}/services/RelCo/getReport`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'code': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'code'))),'label_own': JSON.stringify((await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('chart_own'))),'label_colleague': JSON.stringify((await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('chart_colleague'))),'label_employee': JSON.stringify((await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('chart_employee'))),'label_manager': JSON.stringify((await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('chart_manager'))),'email': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'email'))) }).send());
      if (!(resultSet !== null && !Array.isArray(resultSet) && (typeof resultSet === 'object'))) {
        throw (new Error('email_error'))
      }
      localStorage.setItem((String(getObjectProperty(___arguments.context.pageData, 'code')) + String('-report')), JSON.stringify(resultSet));
    }
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('67e5a8a2272562f41d48f63f83aa6364', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7c1c13557decaa3cf33910b467d911bf', true);
    ___arguments.context.pageData['chartLabels'] = (getObjectProperty(resultSet, 'labels'));
    ___arguments.context.pageData['chartDatasets'] = (getObjectProperty(resultSet, 'datasets'));
    ___arguments.context.pageData['assessment'] = (getObjectProperty(resultSet, 'assessment'));
    ___arguments.context.pageData['stats'] = (getObjectProperty(resultSet, 'stats'));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('2f630a83e33dad85eb8b913fea1e0c6f', (getObjectProperty(___arguments.context.pageData, 'stats.top')));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('320840b1bf95923fec75b7ad15ba41eb', (getObjectProperty(___arguments.context.pageData, 'stats.bottom')));
    if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('2f630a83e33dad85eb8b913fea1e0c6f')).length) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('69f536f3ce1d8cbd7e839cd99bac0899', false);
    }
    if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('320840b1bf95923fec75b7ad15ba41eb')).length) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('41614fa106a55db3ced2466ccb695963', false);
    }

  } catch (error) {
    if ((getObjectProperty(error, 'message')) == 'load_error') {
      ___arguments.context.pageData['errorMessage'] = (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('load_error'));
    } else if ((getObjectProperty(error, 'message')) == 'email_error') {
      ___arguments.context.pageData['errorMessage'] = (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('email_error'));
    } else {
      ___arguments.context.pageData['errorMessage'] = (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('unknown_error'));
      Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((JSON.stringify(error)));
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/report/components/66ad85b328277235506366b6eaae3925/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b20f391e80b40bad68a270a31cbcf9d4']('report_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/report/components/e26568ac6ba7e588614955e4fb1af10b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'disableReportButton')) == true) {
    ___arguments.context.pageData['disableReportButton'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

